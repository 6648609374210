import React from 'react';
import { withPrefix } from 'gatsby';
import { Button } from 'reactstrap';
import Wrapper from '../../components/wrapper';

export default props => {
  const page = props['*'];

  return (
    <Wrapper page={page}>
      <h1 className='d-block d-lg-none page'>Products</h1>
      <div className='row text-center'>
        <div className='col-md'>
          <a href='/products/info'>
            <figure className='figure'>
              <img
                className='figure-img img-fluid img-thumbnail'
                src={withPrefix('/img/product-texe.jpg')}
                alt=''
              />
              <figcaption className='figure-caption'>
                <Button>TEX-E</Button>
              </figcaption>
              <figcaption className='figure-caption mt-3'>
                <blockquote className='blockquote'>
                  <div className='row'>
                    <div className='col-md-10 offset-md-1 text-center'>
                      TEX-E is a triple insulated reinforced winding wire. It is
                      comprised of three individual layers of solderable
                      modified polyester, heat resistant and polyamide resins.
                    </div>
                  </div>
                </blockquote>
              </figcaption>
            </figure>
          </a>
        </div>
        <div className='col-md'>
          <a href='/products/info'>
            <figure className='figure'>
              <img
                alt=''
                className='figure-img img-fluid img-thumbnail'
                src={withPrefix('/img/product-texelz.jpg')}
              />
              <figcaption className='figure-caption'>
                <Button>TEX-ELZ</Button>
              </figcaption>
              <figcaption className='mt-3 figure-caption'>
                <blockquote className='blockquote'>
                  <div className='row'>
                    <div className='col-md-10 offset-md-1 text-center'>
                      TEX-ELZ is a litz wire comprised of seven enameled wires
                      covered with three layers of insulation of solderable
                      modified polyester, thermal and polyamide resins for high
                      frequency applications.
                    </div>
                  </div>
                </blockquote>
              </figcaption>
            </figure>
          </a>
        </div>
      </div>
    </Wrapper>
  );
};
